import styled from '@emotion/styled'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { color, time } from 'driverama-core/styles/variables'
import { Divider } from 'driverama-core/components/divider/Divider'

export const SDivider = styled(Divider)`
  background-color: ${color('night-l-650')};
`

export const SContainer = styled.div<{ isActive: boolean }>`
  ${SDivider} {
    background-color: ${({ isActive }) =>
      color(isActive ? 'night-l-200' : 'night-l-800')};
  }

  ${TextBodyBold} {
    color: ${({ isActive }) => color(isActive ? 'white' : 'night-d-200')};
    transition: all ${time('control')} ease-in-out;
  }
`
