import { css } from '@emotion/react'
import { Switch } from 'driverama-core/components/switch/Switch'
import { TextBody, TextSubhead } from 'driverama-core/components/text/Text'
import IconDoubleChevronDown from 'driverama-core/images/icons/IconDoubleChevronDown.svg'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import {
  AuctionListData,
  AuctionListMode,
  AuctionsSearchData
} from 'sections/auctions/Auctions.utils'
import { AuctionsList } from 'sections/auctions/list/AuctionsList'
import { sortItems } from 'sections/auctions/sidebar/AuctionsSidebar.utils'
import {
  SSidebar,
  SSidebarContent,
  SSidebarHeader,
  SSidebarWrapper,
  SSpinner,
  SSpinnerCentered,
  SSwitchContainer
} from './AuctionsSidebar.styled'

interface Props extends AuctionsSearchData {
  selected: AuctionListData | undefined
  onSelected: (value: AuctionListData) => void
  onModeSelect: (value: AuctionListMode) => void
  mode: AuctionListMode
}

const variants = {
  open: {
    y: 0,
    height: `calc(100% - ${size(40)})`
  },
  closed: {
    y: '100%',
    height: 0
  }
}
export function AuctionsSidebar({
  data,
  isLoading,
  isFetching,
  isError,
  selected,
  onSelected,
  onModeSelect,
  mode
}: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const isSmall = useMedia(media.lte('tablet'), false)
  const [isExpanded, setIsExpanded] = useState<boolean>(!isSmall)

  return (
    <SSidebarWrapper>
      <SSidebarHeader onClick={() => setIsExpanded(!isExpanded)}>
        <motion.div
          animate={{
            transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'
          }}
          css={css`
            display: flex;
          `}
        >
          <IconDoubleChevronDown
            css={css`
              margin-left: ${size(4)};
              margin-right: ${size(2)};
            `}
          />
        </motion.div>
        <TextSubhead>
          {t(
            isExpanded
              ? 'auction:hide_auctions_list'
              : 'auction:show_auctions_list'
          )}
        </TextSubhead>
      </SSidebarHeader>

      <SSidebar
        initial={isSmall ? 'closed' : 'open'}
        animate={isExpanded ? 'open' : 'closed'}
        transition={{ duration: 0.2 }}
        variants={variants}
      >
        <SSidebarContent>
          <SSwitchContainer variant="row" justify="center">
            <Switch
              labels={{
                '15m': t('auction:header_switch_15m'),
                '48h': t('auction:header_switch_48h')
              }}
              value={mode}
              onChange={onModeSelect}
            />
          </SSwitchContainer>

          {!isLoading && isFetching && <SSpinner variant="x-small" />}
          {isLoading ? (
            <SSpinnerCentered variant="small" />
          ) : isError ? (
            <TextBody>{t('core:error_api')}</TextBody>
          ) : (
            <AuctionsList
              data={data.auctions?.sort(sortItems) || []}
              selected={selected}
              onSelected={onSelected}
            />
          )}
        </SSidebarContent>
      </SSidebar>
    </SSidebarWrapper>
  )
}
