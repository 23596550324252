import styled from '@emotion/styled'
import { Flex } from 'driverama-core/components/Flex'
import { Spinner } from 'driverama-core/components/spinner/Spinner'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { styledScrollbars } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, shadow, zIndex } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SSidebarWrapper = styled.div`
  @media print {
    display: none;
  }
`

export const SSpinner = styled(Spinner)`
  position: absolute;
  top: ${size(1)};
  right: ${size(1)};
`

export const SSpinnerCentered = styled(SpinnerCentered)`
  height: 100%;
`

export const SSidebar = styled(motion.aside)`
  --sidebar-top-offset: ${size(44)};
  position: fixed;
  top: --sidebar-top-offset;
  left: 0;

  height: calc(100vh - var(--sidebar-top-offset));
  width: ${size(92)};

  overflow: auto;

  background-color: ${color('white')};

  ${styledScrollbars};

  @media ${media.lte('tablet')} {
    position: absolute;
    top: initial;
    bottom: 0;
    width: 100%;
    box-shadow: ${shadow(3)};
    z-index: ${zIndex('order-4')};
  }
`

export const SSidebarContent = styled.div`
  @media ${media.lte('tablet')} {
    padding-top: ${size(4)};
    padding-bottom: ${size(12)};
  }
`

export const SSidebarHeader = styled.header`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${size(12)};
  padding: ${size(1)};
  z-index: ${zIndex('sticky')};
  background-color: ${color('white')};
  display: flex;
  align-items: center;
  box-shadow: ${shadow(3)};

  @media ${media.gt('tablet')} {
    display: none;
  }
`

export const SSwitchContainer = styled(Flex)`
  @media ${media.gt('tablet')} {
    display: none;
  }
`
