import { css } from '@emotion/react'
import { useDetectPrint } from 'driverama-core/utils/print'
import { useTranslation } from 'react-i18next'

export function PdfLoader() {
  const { t } = useTranslation('core')

  const isPrinting = useDetectPrint()

  return isPrinting ? (
    <div
      css={css`
        position: absolute;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: grid;
        place-content: center;

        @media print {
          display: none;
        }
      `}
    >
      <span
        css={css`
          color: #fff;
        `}
      >
        {t('preparing_pdf')}
      </span>
    </div>
  ) : null
}
