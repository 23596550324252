import { useCarDetailQuery } from 'api/driverama/cars/carDetail'
import { useModal } from 'driverama-core/components/modal/Modal'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { size } from 'driverama-core/styles/spacing'
import { getRemainingTime } from 'driverama-core/utils/time'
import { isNotNil } from 'driverama-core/utils/types'
import { useTranslation } from 'react-i18next'
import { AuctionListData } from 'sections/auctions/Auctions.utils'
import { useCarImages } from 'sections/auctions/detail/gallery/AuctionsDetailGallery.utils'
import { AuctionsModal } from 'sections/auctions/modal/AuctionsModal'
import { logEvent } from 'utils/analytics'
import { SContent } from './AuctionsHeader.styled'

import { css } from '@emotion/react'
import { useDetectPrint } from 'driverama-core/utils/print'
import Head from 'next/head'
import { useSearchAuctionBids } from '../../../api/driverama/auctions/auctionsBids'
import { useSearchAuctionsAgg } from '../../../api/driverama/auctions/searchAuctionsAgg'
import { usePartnersSearchQuery } from '../../../api/driverama/partners/search'
import { useRelatedAuctions } from '../detail/AuctionsDetail.utils'
import { AuctionsEndModal } from '../modal/AuctionsEndModal'
import { AuctionsHeaderActions } from './actions/AuctionsHeaderActions'
import { AuctionHeaderDetails } from './details/AuctionHeaderDetails'
import { AuctionsHeaderTags } from './tags/AuctionsHeaderTags'

export function AuctionsHeader({
  refetchList,
  onPlaceBidAuctionReset,
  ...item
}: AuctionListData & {
  refetchList: () => void
  onPlaceBidAuctionReset: (auction: AuctionListData) => Promise<void>
}) {
  const { t } = useTranslation(['core', 'auction'])

  const {
    id,
    carId,
    plannedEndAtDate,
    plannedEndAt,
    yearOfMake,
    model,
    make,
    speedometerMileageKm,
    engine,
    vin,
    volumeCcm,
    state,
    fuelType,
    powerKw,
    originCountryId
  } = item

  const auctionQuery = useSearchAuctionsAgg({
    ids: [id],
    carIds: [],
    carMakeIds: [],
    states: ['IN_PROGRESS', 'READY_TO_START'],
    types: ['REGULAR', 'REPEATED', 'SEEN'],
    excludedOpportunityStates: ['LOST'],
    opportunityLossReasons: []
  })

  const isPrinting = useDetectPrint()

  const auctionDetail = auctionQuery.data?.content[0]

  const auctionsBidsQuery = useSearchAuctionBids(
    {
      auctionIds: [auctionDetail?.id].filter(isNotNil)
    },
    {
      refetchInterval: 5000,
      enabled: auctionDetail?.state === 'IN_PROGRESS'
    }
  )

  const highestBid = auctionsBidsQuery.data?.content
    .filter(bid => bid.state === 'VALID')
    .sort((a, b) => b.price - a.price)[0]

  const highestBidPartner = usePartnersSearchQuery(
    {
      erpIds: [highestBid?.partnerId].filter(isNotNil)
    },
    {
      enabled: !!highestBid?.partnerId
    }
  )

  const imagesQuery = useCarImages(auctionDetail?.carId, true)

  const carQuery = useCarDetailQuery(carId)

  const {
    previouslyAuctioned,
    restartedAuctions,
    relatedAuctionsQuery
  } = useRelatedAuctions(auctionDetail)

  const [modal, openModal] = useModal(
    () => (
      <AuctionsModal
        auctionId={id}
        plannedEndAt={plannedEndAt}
        onSubmitSuccess={() => {
          toast({ type: 'success', content: t('auction:modal_submit_success') })
          auctionsBidsQuery.refetch()
          auctionQuery.refetch()
          onPlaceBidAuctionReset(item)
        }}
        onRemoveSuccess={() => {
          toast({ type: 'success', content: t('auction:modal_remove_success') })
          auctionsBidsQuery.refetch()
          auctionQuery.refetch()
        }}
      />
    ),
    { closeLabel: t('core:close') }
  )

  const [endModal, openEndModal] = useModal(
    () => (
      <AuctionsEndModal
        auctionId={id}
        onConfirm={() => {
          refetchList()
        }}
      />
    ),
    {
      closeLabel: t('core:close'),
      wrapperStyles: css`
        width: ${size(137.5)};
      `
    }
  )

  const onPlaceBidClick = () => {
    if (plannedEndAt) {
      logEvent('place_bid', {
        auction_id: id,
        remaining_time: getRemainingTime(plannedEndAt)
      })
    }

    openModal()
  }

  const queries = [auctionQuery, imagesQuery, carQuery, relatedAuctionsQuery]

  const isLoading = queries.find(q => q.isLoading)
  const isError = queries.find(q => q.isError)

  const opportunityState = auctionDetail?.opportunity?.state

  const swap = auctionDetail?.opportunity?.swap

  const newCar = carQuery.data?.newWhenLastAuctioned

  const isRestarted = !!restartedAuctions?.length
  const isPreviouslyAuctioned = !!previouslyAuctioned?.length

  return isLoading ? (
    <SpinnerCentered variant="small" />
  ) : isError ? (
    <TextBody>{t('core:error_api')}</TextBody>
  ) : (
    <>
      {modal}
      {endModal}
      {isPrinting && (
        <Head>
          <title>
            {[[make, model].join(' '), yearOfMake, engine, vin].join(' | ')}
          </title>
        </Head>
      )}
      <SContent>
        <AuctionHeaderDetails
          carId={carId}
          make={make}
          model={model}
          engine={engine}
          originCountryId={originCountryId}
          newCar={!!newCar}
          swap={!!swap}
          isRestarted={isRestarted}
          isPreviouslyAuctioned={isPreviouslyAuctioned}
          yearOfMake={yearOfMake}
          opportunityState={opportunityState}
          highestBid={highestBid}
          highestBidPartner={highestBidPartner.data?.content?.[0]}
        />

        <AuctionsHeaderActions
          carId={carId}
          id={id}
          state={state}
          make={make}
          model={model}
          auctionDetail={auctionDetail}
          fuelType={fuelType}
          highestBid={highestBid}
          openEndModal={openEndModal}
          plannedEndAt={plannedEndAt}
          plannedEndAtDate={plannedEndAtDate}
          onPlaceBidClick={onPlaceBidClick}
          powerKw={powerKw}
          speedometerMileageKm={speedometerMileageKm}
          volumeCcm={volumeCcm}
          yearOfMake={yearOfMake}
          highestBidPartner={highestBidPartner.data?.content?.[0]}
        />
      </SContent>

      <AuctionsHeaderTags
        newCar={!!newCar}
        swap={!!swap}
        isRestarted={isRestarted}
        isPreviouslyAuctioned={isPreviouslyAuctioned}
        variant="mobile"
        yearOfMake={yearOfMake}
        opportunityState={opportunityState}
      />
    </>
  )
}
