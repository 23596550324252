import { css } from '@emotion/react'
import { SearchAuctionBidResponse } from 'api/driverama/auctions/auctionsBids'
import { PartnersSearchResponse } from 'api/driverama/partners/search'
import { OpportunityState } from 'driverama-core/api/driverama/opportunities/opportunityDetail'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import { useMedia } from 'react-use'
import { useCarImages } from 'sections/auctions/detail/gallery/AuctionsDetailGallery.utils'
import { AuctionHeaderDetailsBid } from '../bid/AuctionHeaderDetailsBid'
import { AuctionsHeaderTags } from '../tags/AuctionsHeaderTags'
import {
  SDetails,
  SFlag,
  SImageWrapper,
  SInfo,
  SName
} from './AuctionHeaderDetails.styled'

type Props = {
  carId?: string
  make?: string
  model?: string
  originCountryId?: string
  engine?: string
  yearOfMake?: number
  swap: boolean
  newCar: boolean
  isPreviouslyAuctioned: boolean
  isRestarted: boolean
  opportunityState?: OpportunityState | null
  highestBid?: SearchAuctionBidResponse['content'][number]
  highestBidPartner?: PartnersSearchResponse['content'][number]
}

export function AuctionHeaderDetails({
  carId,
  make,
  model,
  engine,
  originCountryId,
  yearOfMake,
  swap,
  newCar,
  isPreviouslyAuctioned,
  isRestarted,
  opportunityState,
  highestBid,
  highestBidPartner
}: Props) {
  const imagesQuery = useCarImages(carId, true)

  const isTablet = useMedia(media.lte('tablet'))

  return (
    <SInfo>
      {imagesQuery.data?.[0] && (
        <>
          <SImageWrapper>
            <Image
              src={imagesQuery.data[0].url}
              width={size(15)}
              height={size(15)}
              objectFit="cover"
            />
          </SImageWrapper>
          <Spacer
            size={2}
            css={css`
              @media print {
                display: none;
              }
            `}
          />
        </>
      )}

      <SDetails>
        <Flex variant="row" align="center">
          <SName variant={['h6', 'h5', 'h5']} as="h5">
            {originCountryId && <SFlag name={originCountryId} />}
            {make} {model} {engine}
          </SName>
        </Flex>

        <AnimatePresence>
          {isTablet && (
            <AuctionHeaderDetailsBid
              variant="mobile"
              highestBid={highestBid}
              highestBidPartner={highestBidPartner}
            />
          )}
        </AnimatePresence>

        <AuctionsHeaderTags
          isRestarted={isRestarted}
          isPreviouslyAuctioned={isPreviouslyAuctioned}
          swap={swap}
          newCar={newCar}
          yearOfMake={yearOfMake}
          variant="desktop"
          opportunityState={opportunityState}
        />
      </SDetails>
    </SInfo>
  )
}
