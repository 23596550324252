import { css } from '@emotion/react'
import { TextBody } from 'driverama-core/components/text/Text'
import { definitions } from 'driverama-core/styles/variables'
import { FC, SVGProps } from 'react'
import {
  SMenuItem,
  SMissingPermissionTooltip
} from './ActionHeaderActionMenu.styled'

interface Props {
  label: string
  Icon: FC<SVGProps<SVGSVGElement>>
  onClick?: () => void
  textColor?: keyof typeof definitions['c']
  disabled?: boolean
  tooltip?: string
}

export function ActionMenuItem({
  label,
  Icon,
  onClick,
  textColor,
  disabled,
  tooltip
}: Props) {
  return (
    <SMenuItem
      onClick={onClick}
      textColor={textColor}
      disabled={disabled}
      css={css`
        opacity: ${disabled ? 0.4 : 1};
      `}
    >
      <TextBody>{label}</TextBody>

      <Icon />

      {tooltip && (
        <SMissingPermissionTooltip>{tooltip}</SMissingPermissionTooltip>
      )}
    </SMenuItem>
  )
}
