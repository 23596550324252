import styled from '@emotion/styled'
import { Flag } from 'driverama-core/components/flag/Flag'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { radius } from 'driverama-core/styles/variables'

export const SContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  gap: ${size(4)};

  @media ${media.lte('tablet')} {
    grid-template-columns: auto ${size(20)};
  }

  @media print {
    grid-template-rows: 1fr auto;
  }
`

export const SImage = styled.div`
  min-width: ${size(15)};
  min-height: ${size(15)};

  overflow: hidden;

  border-radius: ${radius('corner')};
`

export const SFlag = styled(Flag)`
  min-width: ${size(5)};
  min-height: ${size(5)};
  margin-right: ${size(2)};
`

export const SImageWrapper = styled.div`
  border-radius: ${radius('corner')};
  overflow: hidden;
  height: ${size(15)};

  @media ${media.lte('tablet')} {
    display: none;
  }
`
