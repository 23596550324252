import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ButtonCircle,
  ButtonTransparent
} from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, definitions } from 'driverama-core/styles/variables'

export const SButtonCircle = styled(ButtonCircle)<{ isOpen: boolean }>`
  --btn-size: ${size(12)};

  align-self: flex-end;

  width: var(--btn-size);
  height: var(--btn-size);

  @media ${media.lte('mobile')} {
    --btn-size: ${size(11)};
  }
`

export const SMenuItem = styled(ButtonTransparent)<{
  textColor?: keyof typeof definitions['c']
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${size(60)};
  width: 100%;

  padding: 10px 0;

  color: ${color('night-text')};

  ${({ textColor }) =>
    textColor &&
    css`
      & span,
      svg {
        color: ${color(textColor)};
      }
    `}
  svg {
    width: ${size(5)};
    height: ${size(5)};
  }
`

export const SMissingPermissionTooltip = styled(TextBody)`
  position: absolute;
  font-size: 11px;
  color: ${color('night-text')} !important;

  top: ${size(7)};
  left: 0px;
`
