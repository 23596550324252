import styled from '@emotion/styled'
import IconHourglass from 'images/icons/IconHourglass.svg'
import { size } from 'driverama-core/styles/spacing'
import { color, time } from 'driverama-core/styles/variables'
import { TextBodyBold } from 'driverama-core/components/text/Text'

export const SContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;

  color: ${({ isActive }) => color(isActive ? 'majesty-l-100' : 'majesty')};
`

export const SIcon = styled(IconHourglass)`
  width: ${size(4)};
  height: ${size(4)};

  transition: all ${time('control')} ease-in-out;
`

export const SText = styled(TextBodyBold)`
  text-transform: uppercase;
  color: inherit;
  transition: all ${time('control')} ease-in-out;
`
