import styled from '@emotion/styled'
import { color, radius, shadow, time } from 'driverama-core/styles/variables'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { css } from '@emotion/react'
import { Dot } from 'driverama-core/components/Dot'
import { Flag } from 'driverama-core/components/flag/Flag'
import { media } from 'driverama-core/styles/media'

export const SContainer = styled.div`
  position: relative;
  width: 100%;
`

export const SContent = styled.div<{
  isActive: boolean
  showHighlight: boolean
}>`
  position: relative;

  width: 100%;

  padding: ${size(5)};

  box-shadow: ${shadow(3)};
  border-radius: ${radius('corner')};

  transition: all ${time('control')} ease-in-out;

  ${({ showHighlight, isActive }) =>
    showHighlight &&
    css`
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: ${size(1)};
        height: 100%;

        background-color: ${color(isActive ? 'majesty-l-100' : 'majesty', 0.4)};
        transition: all ${time('control')} ease-in-out;
      }
    `}

  ${({ isActive }) => css`
    background-color: ${color(isActive ? 'night-l-100' : 'white')};

    ${SHeadline} {
      color: ${color(isActive ? 'white' : 'night-d-200')};
      transition: all ${time('control')} ease-in-out;
    }

    ${TextBody}, ${Dot} {
      color: ${color(isActive ? 'night-l-650' : 'night-text')};
      transition: all ${time('control')} ease-in-out;
    }

    ${SFlag} {
      border-color: ${color('white')};
    }
  `};

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  @media ${media.lte('tablet')} {
    padding: ${size(3)};
  }
`

export const SDetails = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${spaceX(2)};
`

export const SHeadline = styled(TextHeader)`
  display: flex;
  justify-content: space-between;
`

export const SFlag = styled(Flag)`
  min-width: ${size(6)};
  min-height: ${size(6)};
  border: 2px solid transparent;
  border-radius: ${radius('full')};
`
