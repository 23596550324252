import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size, spaceY } from 'driverama-core/styles/spacing'

export const SList = styled.div`
  padding: ${size(6)} ${size(10)};
  ${spaceY(3)};

  @media ${media.lte('tablet')} {
    padding: ${size(2)};
  }
`
