import { SContainer, SCountdown, SIcon } from './AuctionsItemTimer.styled'
import { Dot } from 'driverama-core/components/Dot'
import { useState } from 'react'
import { createTimerString } from 'driverama-core/utils/strings'
import { useInterval } from 'react-use'

interface Props {
  isActive: boolean
  endDate?: Date
}

export function AuctionsItemTimer({ isActive, endDate }: Props) {
  const [timer, setTimer] = useState(createTimerString(endDate))

  useInterval(() => {
    setTimer(createTimerString(endDate))
  }, 1000)

  return (
    <>
      <SContainer isActive={isActive}>
        <SIcon />
        <SCountdown>{timer}</SCountdown>
      </SContainer>
      <Dot />
    </>
  )
}
