import { SContainer, SIcon, SText } from './AuctionsItemComingSoon.styled'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Dot } from 'driverama-core/components/Dot'
import { useTranslation } from 'react-i18next'

interface Props {
  isActive: boolean
}

export function AuctionsItemComingSoon({ isActive }: Props) {
  const { t } = useTranslation(['auction'])

  return (
    <>
      <SContainer isActive={isActive}>
        <SIcon />
        <Spacer axis="horizontal" size={1} />
        <SText>{t('auction:item_state_soon')}</SText>
      </SContainer>
      <Dot />
    </>
  )
}
