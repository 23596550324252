import { Flex } from 'driverama-core/components/Flex'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { usePartnerMapper } from '../../../../api/driverama/partners/search'

interface Props {
  amount?: number
  partnerId?: string
}

export function AuctionsItemBid({ amount, partnerId }: Props) {
  const { t } = useTranslation(['auction'])
  const partnerMapper = usePartnerMapper()

  return (
    <Flex variant="row" justify="between">
      <TextBody>
        {partnerId ? partnerMapper(partnerId) : t('auction:item_your_bid')}
      </TextBody>
      <TextBodyBold>{t('auction:price', { price: amount })}</TextBodyBold>
    </Flex>
  )
}
