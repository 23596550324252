import styled from '@emotion/styled'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, weight } from 'driverama-core/styles/variables'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'

export const SContainer = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
`

export const SSubtitle = styled(TextBody)`
  text-align: center;
`

export const SInput = styled.div`
  width: 100%;
`

export const SRow = styled(TextBody)`
  display: flex;
  justify-content: space-between;

  width: 100%;

  strong {
    font-weight: ${weight('medium')};
  }
`

export const SPayRow = styled(TextHeader)`
  display: flex;
  justify-content: space-between;

  width: 100%;
`

export const SActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size(4)};

  width: 100%;

  @media ${media.gt('mobile')} {
    flex-direction: row;
  }
`

export const SButton = styled(Button)`
  width: 100%;
`

export const SRedButton = styled(SButton)`
  color: ${color('warning')};
  border-color: ${color('warning')};
`

export const SSplitInput = styled.div<{ variant: 'large' | 'small' }>`
  flex: ${({ variant }) => (variant === 'large' ? 2 : 1)};
`
