import { Header } from 'components/header/Header'
import { Layout, Main } from 'components/layout/Layout'
import { PdfLoader } from 'components/pdfLoader/PdfLoader'
import { SubHeader } from 'components/subHeader/SubHeader'
import { LINKS } from 'constants/links'
import { AuthRedirectType } from 'driverama-core/auth/next'
import { GetStaticProps } from 'next'
import { useCallback, useState } from 'react'
import {
  AuctionListData,
  AuctionListMode,
  useAuctionAutoSelect,
  useAuctionNotifications,
  useAuctions
} from 'sections/auctions/Auctions.utils'
import { AuctionsDetailEmpty } from 'sections/auctions/detail/empty/AuctionsDetailEmpty'
import { AuctionsDetailError } from 'sections/auctions/detail/error/AuctionsDetailError'
import { AuctionsHeader } from 'sections/auctions/header/AuctionsHeader'
import { AuctionsSidebar } from 'sections/auctions/sidebar/AuctionsSidebar'
import { getIntlProps } from 'utils/intl'
import { AuctionsDetailWrapper } from '../sections/auctions/detail/AuctionsDetailWrapper'

export default function Page() {
  const [mode, setMode] = useState<AuctionListMode>('15m')
  const [auction, setAuction] = useState<AuctionListData>()

  const onModeSelect = useCallback(
    (value: AuctionListMode) => setMode(value),
    []
  )
  const onSelected = useCallback(
    (value: AuctionListData | undefined) => setAuction(value),
    []
  )

  const auctions = useAuctions({
    mode
  })
  useAuctionNotifications(mode, auctions.data.auctions?.length || 0)
  useAuctionAutoSelect(
    auctions.data.auctions,
    auctions.isLoading,
    auction,
    onSelected
  )

  // to update current active auction with fresh data after placing a bid
  const onPlaceBidAuctionReset = useCallback(
    async (auction: AuctionListData) => {
      const refetchedAuctionList = await auctions.refetch()

      const updatedAuction = refetchedAuctionList.data?.content.find(
        currAuction => currAuction.id === auction.id
      )

      if (updatedAuction) {
        setAuction({ ...auction, ...updatedAuction })
      }
    },
    [auctions]
  )

  return (
    <Layout>
      <PdfLoader />

      <Header />

      <SubHeader mode={mode} onModeSelect={onModeSelect}>
        {auction && (
          <AuctionsHeader
            {...auction}
            onPlaceBidAuctionReset={onPlaceBidAuctionReset}
            refetchList={() => {
              setAuction(undefined)
            }}
          />
        )}
      </SubHeader>

      <AuctionsSidebar
        {...auctions}
        selected={auction}
        onSelected={onSelected}
        onModeSelect={onModeSelect}
        mode={mode}
      />

      <Main>
        {!auctions.isLoading && !auctions.data.auctions?.length ? (
          <AuctionsDetailEmpty />
        ) : !auctions.isLoading && (auctions.isError || !auctions.data) ? (
          <AuctionsDetailError />
        ) : (
          auction && (
            <AuctionsDetailWrapper
              carId={auction.carId || ''}
              auctionId={auction.id}
              mode={mode}
              auctionHasBid={!!auction.validBids?.length}
              auctionState={auction.state}
              plannedEndAt={auction.plannedEndAt || ''}
            />
          )
        )}
      </Main>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['auction'])
})

Page.auth = {
  type: AuthRedirectType.RequiresAuth,
  destination: LINKS.login
}
