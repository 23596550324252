import { SContainer, SText, SDivider } from './AuctionsItemFooter.styled'
import { useTranslation } from 'react-i18next'

interface Props {
  restartedAuction: boolean
  previouslyAuctioned: boolean
}

export function AuctionsItemFooter(props: Props) {
  const { t } = useTranslation(['auction'])

  return (
    <SContainer>
      {props.restartedAuction && (
        <>
          <SText>{t('auction:item_footer_restarted')}</SText>
          {props.previouslyAuctioned && <SDivider />}
        </>
      )}
      {props.previouslyAuctioned && (
        <SText>{t('auction:item_footer_auctioned')}</SText>
      )}
    </SContainer>
  )
}
