import { SList } from './AuctionsList.styled'
import { AuctionListData } from 'sections/auctions/Auctions.utils'
import { AuctionsItem } from 'sections/auctions/item/AuctionsItem'

interface Props {
  data: AuctionListData[]
  selected: AuctionListData | undefined
  onSelected: (value: AuctionListData) => void
}

export function AuctionsList({ data, selected, onSelected }: Props) {
  return (
    <SList>
      {data.map(item => (
        <AuctionsItem
          key={item.id}
          isActive={item.id === selected?.id}
          onClick={() => onSelected(item)}
          {...item}
        />
      ))}
    </SList>
  )
}
