import styled from '@emotion/styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { Button } from 'driverama-core/components/button/Button'

export const SContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SMessage = styled(TextBody)`
  max-width: ${size(77)};

  text-align: center;
`

export const SButton = styled(Button)`
  width: 100%;
  max-width: ${size(58)};
`
