import { useTranslation } from 'react-i18next'
import { Flex } from 'driverama-core/components/Flex'
import { TextHeader } from 'driverama-core/components/text/Text'
import { Button } from 'driverama-core/components/button/Button'
import { css } from '@emotion/react'
import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import { useEndAuction } from '../../../api/driverama/auctions/endAuctions'
import { toast } from 'driverama-core/components/toast/Toast'

type Props = {
  auctionId: string
  onConfirm: () => void
}

export function AuctionsEndModal(props: Props) {
  const { t } = useTranslation(['core', 'auction'])

  const { mutateAsync, isLoading } = useEndAuction(props.auctionId)

  const closeModal = useCloseModalContext()

  const handleEndAuction = async () => {
    try {
      await mutateAsync({})
      props.onConfirm()
      closeModal()
    } catch (error) {
      toast({ type: 'error', content: t('core:error_api'), error })
    }
  }

  return (
    <Flex variant="column" gap={10}>
      <TextHeader variant="h3">{t('auction:modal_end_title')}</TextHeader>
      <Flex variant="row" gap={4}>
        <Button
          disabled={isLoading}
          variant="outline"
          css={css`
            flex: 1 50%;
          `}
          onClick={closeModal}
        >
          {t('core:close')}
        </Button>
        <Button
          disabled={isLoading}
          variant="warning"
          css={css`
            flex: 1 50%;
          `}
          onClick={handleEndAuction}
        >
          {t('core:confirm')}
        </Button>
      </Flex>
    </Flex>
  )
}
