import { css } from '@emotion/react'
import { SearchAuctionBidResponse } from 'api/driverama/auctions/auctionsBids'
import { AuctionSearchResponseAggItem } from 'api/driverama/auctions/searchAuctionsAgg'
import { PartnersSearchResponse } from 'api/driverama/partners/search'
import { useHasRoles } from 'driverama-core/auth/openid/useRoles'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { isNumber } from 'driverama-core/utils/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval, useMedia } from 'react-use'
import {
  AuctionListData,
  createBidButtonTimerString
} from 'sections/auctions/Auctions.utils'
import { AuctionHeaderDetailsBid } from '../bid/AuctionHeaderDetailsBid'
import {
  SActions,
  SButtonEndNow,
  SButtonPlaceBid,
  SButtonPricing
} from './AuctionsHeaderActions.styled'
import { AuctionHeaderActionMenu } from './menu/AuctionHeaderActionMenu'

type Props = {
  carId?: string
  id: string
  make?: string
  model?: string
  speedometerMileageKm?: number
  yearOfMake?: number
  powerKw?: number
  volumeCcm?: number
  plannedEndAt?: string | null
  plannedEndAtDate?: Date
  fuelType?: string
  state: AuctionListData['state']
  auctionDetail?: AuctionSearchResponseAggItem
  openEndModal: () => Promise<void>
  onPlaceBidClick: () => void
  highestBid?: SearchAuctionBidResponse['content'][number]
  highestBidPartner?: PartnersSearchResponse['content'][number]
}

export function AuctionsHeaderActions({
  carId,
  id,
  make,
  model,
  speedometerMileageKm,
  state,
  yearOfMake,
  powerKw,
  volumeCcm,
  fuelType,
  plannedEndAt,
  plannedEndAtDate,
  openEndModal,
  onPlaceBidClick,
  auctionDetail,
  highestBid,
  highestBidPartner
}: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const isXL = useMedia(media.lte('XL'))

  const hasRoles = useHasRoles([
    'AUCTIONS:AUCTIONBID:CREATE_OR_UPDATE_AUCTION_BID',
    'AUCTIONS:AUCTION:END_AUCTION'
  ])

  const canEndAuction = hasRoles['AUCTIONS:AUCTION:END_AUCTION']
  const canPlaceBid =
    hasRoles['AUCTIONS:AUCTIONBID:CREATE_OR_UPDATE_AUCTION_BID']

  const [timer, setTimer] = useState(
    plannedEndAtDate ? createBidButtonTimerString(plannedEndAtDate) : undefined
  )

  useInterval(() => {
    plannedEndAtDate && setTimer(createBidButtonTimerString(plannedEndAtDate))
  }, 1000)

  const getPricingAppLink = () => {
    const url = new URL(`${process.env.NEXT_PUBLIC_PRICING_APP}`)
    const bpmPrice = auctionDetail?.opportunity?.bpm?.reclaimAmountEur
    const region = process.env.NEXT_PUBLIC_REGION

    url.searchParams.append('auction_id', id)
    url.searchParams.append('make', make || '')
    url.searchParams.append('model', model || '')
    url.searchParams.append('km', speedometerMileageKm?.toString() || '')
    url.searchParams.append('year', yearOfMake?.toString() || '')
    url.searchParams.append('fuel', fuelType || '')
    url.searchParams.append('size_ccm', volumeCcm?.toString() || '')
    url.searchParams.append('power', powerKw?.toString() || '')
    url.searchParams.append('dri_country_code', region?.toString() || '')

    if (isNumber(bpmPrice)) {
      url.searchParams.append('bpm', bpmPrice.toString() || '')
    }

    return url.href
  }

  const openPricingApp = () => {
    window.open(getPricingAppLink(), '_blank')
  }

  return (
    <SActions>
      {carId && isXL && (
        <AuctionHeaderActionMenu
          carId={carId}
          highestBid={highestBid}
          state={state}
          openEndModal={openEndModal}
          onPlaceBidClick={onPlaceBidClick}
          openPricingApp={openPricingApp}
          canEndAuction={canEndAuction}
          canPlaceBid={canPlaceBid}
        />
      )}

      {!isXL && (
        <>
          {!highestBid ? (
            <Tooltip
              content={t('auction:header_place_bid_missing_role_tooltip')}
              placement="bottom"
              //disable tooltip when it is not needed (when user actually has a role to perform an action)
              isDisabled={canPlaceBid}
              css={css`
                margin-right: ${size(-2)};
              `}
            >
              <SButtonPricing
                variant="outline"
                disabled={state !== 'IN_PROGRESS' || !canPlaceBid}
                onClick={openPricingApp}
                css={css`
                  margin-right: ${size(3)};
                `}
              >
                {t('auction:header_button_pricing_app')}
              </SButtonPricing>
            </Tooltip>
          ) : (
            <AuctionHeaderDetailsBid
              variant="desktop"
              highestBid={highestBid}
              highestBidPartner={highestBidPartner}
            />
          )}
          <Spacer axis="horizontal" size={2} />
          {state === 'IN_PROGRESS' && (
            <Tooltip
              content={t('auction:header_end_auction_missing_role_tooltip')}
              placement="bottom"
              //disable tooltip when it is not needed (when user actually has a role to perform an action)
              isDisabled={canEndAuction}
              css={css`
                margin-right: ${size(-2)};
              `}
            >
              <SButtonEndNow
                variant="warning"
                onClick={openEndModal}
                disabled={!canEndAuction}
                css={css`
                  margin-right: ${size(3)};
                `}
              >
                {t('auction:header_button_end_now')}
              </SButtonEndNow>
            </Tooltip>
          )}

          <Spacer axis="horizontal" size={2} />

          <Tooltip
            content={t('auction:header_place_bid_missing_role_tooltip')}
            placement="bottom"
            //disable tooltip when it is not needed (when user actually has a role to perform an action)
            isDisabled={canPlaceBid}
            css={css`
              margin-right: ${size(-2)};
            `}
          >
            <SButtonPlaceBid
              variant="secondary"
              onClick={onPlaceBidClick}
              disabled={state !== 'IN_PROGRESS' || !canPlaceBid}
              css={css`
                margin-right: ${size(3)};
              `}
            >
              {t('auction:header_button_place_bid')}
              {state === 'IN_PROGRESS' &&
                plannedEndAt &&
                t('auction:header_button_timer', { countdown: timer })}
            </SButtonPlaceBid>
          </Tooltip>
        </>
      )}
    </SActions>
  )
}
