import styled from '@emotion/styled'
import { size, spaceX } from 'driverama-core/styles/spacing'
import IconClock from 'images/icons/IconClock.svg'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { color, time } from 'driverama-core/styles/variables'

export const SContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;

  ${spaceX(1)};

  color: ${({ isActive }) => color(isActive ? 'white' : 'night-l-100')};
`

export const SIcon = styled(IconClock)`
  width: ${size(4)};
  height: ${size(4)};

  transition: all ${time('control')} ease-in-out;
`

export const SCountdown = styled(TextBodyBold)`
  color: inherit;
  transition: all ${time('control')} ease-in-out;
`
