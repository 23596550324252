import { PropsWithChildren } from 'react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SContainer, SDivider } from './AuctionsItemBids.styled'

interface AuctionsItemBids {
  isActive: boolean
}

export function AuctionsItemBids({
  isActive,
  children
}: PropsWithChildren<AuctionsItemBids>) {
  return (
    <SContainer isActive={isActive}>
      <Spacer axis="vertical" size={2} />
      <SDivider />
      <Spacer axis="vertical" size={2} />
      {children}
    </SContainer>
  )
}
