import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type MutationResponse =
  | paths['/admin/auctions/{auctionId}/end']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

export async function endAuction(auctionId: string) {
  const response = await apiAuthFetcher<MutationResponse>(
    URLS.auctionEnd(auctionId),
    {
      method: HTTPMethod.POST,
      body: {}
    }
  )

  return response.json
}

export function useEndAuction(
  auctionId: string,
  opts?: UseMutationOptions<MutationResponse, unknown, unknown>
) {
  return useMutation<MutationResponse, unknown, unknown>(
    async () => await endAuction(auctionId),
    opts
  )
}
