import { Switch } from 'driverama-core/components/switch/Switch'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AuctionListMode } from 'sections/auctions/Auctions.utils'
import { SContainer, SSwitchContainer } from './SubHeader.styled'

export function SubHeader({
  children,
  mode,
  onModeSelect
}: {
  mode: AuctionListMode
  onModeSelect: (value: AuctionListMode) => void
  children?: ReactNode
}) {
  const { t } = useTranslation(['core', 'auction'])

  return (
    <SContainer>
      <SSwitchContainer variant="row" align="center" justify="center">
        <Switch
          labels={{
            '15m': t('auction:header_switch_15m'),
            '48h': t('auction:header_switch_48h')
          }}
          value={mode}
          onChange={onModeSelect}
        />
      </SSwitchContainer>
      {children}
    </SContainer>
  )
}
