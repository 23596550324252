import { SButton, SContainer, SMessage } from './AuctionsDetailEmpty.styled'
import AuctionEmptyImage from 'images/AuctionEmptyImage.svg'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { LINKS } from 'constants/links'

export function AuctionsDetailEmpty() {
  const { t } = useTranslation(['auction'])
  const router = useRouter()

  return (
    <SContainer>
      <AuctionEmptyImage />
      <Spacer axis="vertical" size={8} />
      <TextHeader variant="h3">{t('auction:no_auctions_title')}</TextHeader>
      <Spacer axis="vertical" size={4} />
      <SMessage>{t('auction:no_auctions_message')}</SMessage>
      <Spacer axis="vertical" size={8} />
      <SButton variant="primary" onClick={() => router.push(LINKS.history)}>
        {t('auction:no_auctions_button')}
      </SButton>
    </SContainer>
  )
}
