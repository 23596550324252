import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, font, radius, shadow } from 'driverama-core/styles/variables'
import { ReactNode } from 'react'

const SwitchButton = styled.button<{
  active?: boolean
  activeColor: string | undefined
  small?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  padding: ${size(2)} ${size(8)};
  padding-bottom: calc(${size(2)} - 2px);

  cursor: pointer;
  color: ${color('night-text')};

  @media ${media.lte('tablet')} {
    padding: ${size(2)} ${size(6)};
    padding-bottom: calc(${size(2)} - 2px);
  }

  @media ${media.lte('mobile')} {
    padding: ${size(1)} ${size(4)};
    padding-bottom: calc(${size(1)} - 2px);

    ${TextBody} {
      font-size: ${size(3)};
    }
  }

  ${({ active, activeColor }) =>
    active &&
    css`
      border-radius: ${radius('full')};
      background-color: ${activeColor ?? color('night-l-100')};
      color: ${color('white')};
      box-shadow: ${shadow(2)};
    `}

  ${({ small }) =>
    small &&
    css`
      padding-left: ${size(3)};
      padding-right: ${size(3)};
    `}
`

export function Switch<T extends Record<string, ReactNode>>(props: {
  labels: T
  value?: keyof T
  onChange?: (value: keyof T) => void
  labelsActiveColor?: Record<string, string>
  buttonStyles?: SerializedStyles
  small?: boolean
  className?: string
}) {
  return (
    <span
      css={css`
        border-radius: ${radius('full')};
        background: ${color('almond-l-200')};
        font-family: ${font('text')};
        color: ${color('night-text')};

        display: flex;
        align-items: stretch;
        cursor: pointer;

        @media ${media.lte('mobile')} {
          min-height: ${size(8)};
          min-width: ${size(40)};
        }
      `}
      className={props.className}
    >
      {Object.entries(props.labels).map(([key, value]) => (
        <SwitchButton
          type="button"
          key={key}
          css={props.buttonStyles}
          active={props.value === key}
          onClick={e => {
            e.stopPropagation()
            props.onChange?.(key)
          }}
          activeColor={
            props.labelsActiveColor?.[key]
              ? props.labelsActiveColor?.[key]
              : undefined
          }
          small={props.small}
        >
          <TextBody
            size="small"
            variant="setup"
            css={css`
              color: inherit;
            `}
          >
            {value}
          </TextBody>
        </SwitchButton>
      ))}
    </span>
  )
}
