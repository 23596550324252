import { Dot } from 'driverama-core/components/Dot'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { AuctionListData } from 'sections/auctions/Auctions.utils'
import { AuctionsItemBid } from 'sections/auctions/item/bid/AuctionsItemBid'
import { AuctionsItemComingSoon } from 'sections/auctions/item/comingSoon/AuctionsItemComingSoon'
import { AuctionsItemFooter } from 'sections/auctions/item/footer/AuctionsItemFooter'
import { AuctionsItemTimer } from 'sections/auctions/item/timer/AuctionsItemTimer'
import { useNotificationPermission } from 'utils/permission'
import { usePartnerId } from '../../../utils/partnerId'
import {
  SContainer,
  SContent,
  SDetails,
  SFlag,
  SHeadline
} from './AuctionsItem.styled'
import { AuctionsItemBids } from './bid/AuctionsItemBids'

interface Props extends AuctionListData {
  isActive: boolean
  onClick: () => void
}

export function AuctionsItem({ isActive, onClick, ...item }: Props) {
  const {
    make,
    model,
    engine,
    state,
    plannedEndAt,
    plannedEndAtDate,
    yearOfMake,
    speedometerMileageKm,
    originCountryId,
    auctionBids
  } = item

  const { t } = useTranslation(['auction'])
  const prevState = usePrevious(state)
  const permission = useNotificationPermission()
  const partnerId = usePartnerId()

  useEffect(() => {
    if (prevState === 'READY_TO_START' && state === 'IN_PROGRESS') {
      if (permission === 'granted')
        new Notification(t('auction:notification_start_title'), {
          body: t('auction:notification_start_body'),
          icon: 'android-chrome-192x192.png'
        })
    }
  }, [permission, prevState, state, t])

  const myBid = auctionBids?.find(bid => bid.partnerId === partnerId)

  const otherPartnersBids = auctionBids?.filter(
    bid => bid.partnerId !== partnerId
  )

  const isRestarted = item.previousAuctionIdsByCarId.length > 0
  const isPreviouslyAuctioned = item.previousAuctionIdsByVin.length > 0

  return (
    <SContainer>
      <SContent
        isActive={isActive}
        onClick={onClick}
        showHighlight={state === 'READY_TO_START'}
      >
        <SHeadline variant="h6" as="h6">
          <span>
            {make} {model} {engine}
          </span>

          {originCountryId && <SFlag name={originCountryId} />}
        </SHeadline>

        <Spacer axis="vertical" size={2} />
        <SDetails>
          {plannedEndAt && (
            <AuctionsItemTimer isActive={isActive} endDate={plannedEndAtDate} />
          )}
          {state === 'READY_TO_START' && (
            <AuctionsItemComingSoon isActive={isActive} />
          )}
          <TextBody>{yearOfMake}</TextBody>
          <Dot />
          <TextBody>
            {t('auction:detail_parameters_mileage_value', {
              value: speedometerMileageKm
            })}
          </TextBody>
        </SDetails>

        {(!!myBid || !!otherPartnersBids?.length) && (
          <AuctionsItemBids isActive={isActive}>
            {!!myBid && <AuctionsItemBid amount={myBid.price} />}
            {otherPartnersBids?.map(bid => (
              <AuctionsItemBid
                key={bid.id}
                amount={bid.price}
                partnerId={bid.partnerId}
              />
            ))}
          </AuctionsItemBids>
        )}
      </SContent>
      {(isRestarted || isPreviouslyAuctioned) && (
        <AuctionsItemFooter
          restartedAuction={isRestarted}
          previouslyAuctioned={isPreviouslyAuctioned}
        />
      )}
    </SContainer>
  )
}
