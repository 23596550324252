import styled from '@emotion/styled'
import { Flag } from 'driverama-core/components/flag/Flag'
import { TextHeader } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { radius } from 'driverama-core/styles/variables'

export const SDetails = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2px;
  max-width: 100%;

  @media ${media.lte('tablet')} {
    flex-wrap: wrap;
    gap: ${size(1)};
    justify-content: center;
  }
`
export const SInfo = styled.div`
  --info-padding: 0 ${size(10)};
  display: flex;
  align-items: center;

  padding: var(--info-padding);
  /* overflow: hidden; */

  @media ${media.lte('laptop')} {
    --info-padding: 0 ${size(2)};
  }

  @media ${media.lte('tablet')} {
    padding: ${size(1)} ${size(2)};
  }

  @media print {
    padding: ${size(5)} ${size(4)} 0;
  }
`

export const SImageWrapper = styled.div`
  border-radius: ${radius('corner')};
  overflow: hidden;
  height: ${size(15)};

  @media ${media.lte('tablet')} {
    display: none;
  }
`

export const SName = styled(TextHeader)`
  @media ${media.lte('tablet')} {
    display: flex;
    align-items: center;
  }
`

export const SFlag = styled(Flag)`
  min-width: ${size(5)};
  min-height: ${size(5)};
  margin-right: ${size(2)};
`
