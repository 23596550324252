import styled from '@emotion/styled'
import { color, radius } from 'driverama-core/styles/variables'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { Divider } from 'driverama-core/components/divider/Divider'

export const SContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: -${size(4)};
  padding: ${size(6)} ${size(4)} ${size(2)};
  gap: ${size(2)};

  color: ${color('night-text')};
  background-color: ${color('almond-l-200')};

  border-bottom-left-radius: ${radius('corner')};
  border-bottom-right-radius: ${radius('corner')};
`

export const SText = styled(TextBodyBold)`
  text-transform: uppercase;
  letter-spacing: 1px;

  font-size: 11px;
  line-height: 18px;

  color: inherit;
`

export const SDivider = styled(Divider)`
  background: ${color('almond-l-100')};
  height: 1px;
`
