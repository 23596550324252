import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Dot } from 'driverama-core/components/Dot'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SWrapper = styled.div<{ variant: 'desktop' | 'mobile' }>`
  display: flex;
  align-items: center;
  gap: ${size(1)};

  position: relative;
  overflow: hidden;

  ${({ variant }) =>
    variant === 'mobile'
      ? css`
          display: none;
          padding: ${size(2)} ${size(4)};

          @media ${media.lte('tablet')} {
            display: flex;
          }
        `
      : css`
          display: flex;

          @media ${media.lte('tablet')} {
            display: none;
          }
        `}

  @media print {
    padding: 0 ${size(4)};
  }
`

export const SBackground = styled.div`
  position: absolute;
  width: ${size(8)};
  height: ${size(6)};
  right: -1px;
  top: 0px;

  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 63.7%);

  @media ${media.lte('tablet')} {
    width: ${size(20)};
    top: ${size(2)};
  }
`

export const SDot = styled(Dot)<{ variant: 'desktop' | 'mobile' }>`
  min-width: 4px;

  ${({ variant }) =>
    variant === 'mobile' &&
    css`
      display: none;
    `}
`
