import { css } from '@emotion/react'
import { OpportunityResult } from 'components/opportunity/OpportunityResult'
import { OpportunityState } from 'driverama-core/api/driverama/opportunities/opportunityDetail'
import { Flex } from 'driverama-core/components/Flex'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBody } from 'driverama-core/components/text/Text'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import IconInfo from 'driverama-core/images/icons/IconCircleInfo.svg'
import IconRotateCcw from 'driverama-core/images/icons/IconRotateCcw.svg'
import IconClock from 'images/icons/IconClock.svg'

import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { useTranslation } from 'react-i18next'
import { SBackground, SDot, SWrapper } from './AuctionsHeaderTags.styled'

import { invisibleScrollbars } from 'driverama-core/styles/common'

type Props = {
  variant: 'desktop' | 'mobile'
  yearOfMake?: number
  opportunityState?: OpportunityState | null
  newCar: boolean
  swap: boolean
  isRestarted: boolean
  isPreviouslyAuctioned: boolean
}

export function AuctionsHeaderTags({
  yearOfMake,
  variant,
  opportunityState,
  newCar,
  swap,
  isRestarted,
  isPreviouslyAuctioned
}: Props) {
  const { t } = useTranslation(['core', 'auction'])

  return (
    <SWrapper variant={variant}>
      <SBackground />

      <TextBody>{yearOfMake}</TextBody>

      {variant === 'mobile' && opportunityState && (
        <>
          <SDot variant={variant} />
          <OpportunityResult result={opportunityState} />
        </>
      )}

      <Flex
        variant="row"
        align="center"
        css={css`
          gap: ${size(1)};
          overflow-x: scroll;
          max-width: 100%;

          padding-right: ${size(2)};

          ${invisibleScrollbars};

          @media ${media.lte('tablet')} {
            gap: ${size(2)};
            padding-right: ${size(1)};
          }
        `}
      >
        {swap && (
          <>
            <SDot variant={variant} />
            <Tag variant="majesty">{t('auction:header_swap')}</Tag>
          </>
        )}

        {newCar && (
          <>
            <SDot variant={variant} />
            <Tag variant="tertiary">
              <Tooltip
                maxWidth="190px"
                content={t('auction:header_new_car_tooltip')}
                placement="bottom"
                css={css`
                  margin-left: ${size(3)};
                  display: inline-block;
                  margin-right: ${size(2)};
                `}
              >
                <IconInfo
                  css={css`
                    margin-left: -${size(3)};
                    width: ${size(4)};
                    height: ${size(4)};
                    vertical-align: text-bottom;
                  `}
                />
              </Tooltip>

              {t('auction:header_new_car')}
            </Tag>
          </>
        )}

        {isPreviouslyAuctioned && (
          <>
            <SDot variant={variant} />
            <Tag variant="tertiary">
              {variant === 'desktop' && (
                <IconClock
                  css={css`
                    margin-right: ${size(1)};
                    width: ${size(4)};
                    height: ${size(4)};
                    vertical-align: text-bottom;
                  `}
                />
              )}
              {t('auction:header_previously_auctioned')}
            </Tag>
          </>
        )}

        {isRestarted && (
          <>
            <SDot variant={variant} />
            <Tag variant="warning-secondary">
              {variant === 'desktop' && (
                <IconRotateCcw
                  css={css`
                    margin-right: ${size(1)};
                    width: ${size(4)};
                    height: ${size(4)};
                    vertical-align: text-bottom;
                  `}
                />
              )}
              {t('auction:header_restarted_auction')}
            </Tag>
          </>
        )}
      </Flex>
    </SWrapper>
  )
}
