import styled from '@emotion/styled'
import { Flex } from 'driverama-core/components/Flex'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, shadow, zIndex } from 'driverama-core/styles/variables'

export const SContainer = styled.section`
  z-index: ${zIndex('order-4')};

  display: grid;
  grid-template-columns: ${size(92)} 1fr;

  background-color: ${color('white')};
  box-shadow: ${shadow(3)};

  @media ${media.lte('laptop')} {
    height: ${size(24)};
  }

  @media ${media.lte('tablet')} {
    height: fit-content;
    grid-template-columns: 1fr;
  }

  @media print {
    box-shadow: none;
  }
`

export const SSwitchContainer = styled(Flex)`
  @media ${media.lte('tablet')} {
    display: none;
  }
`
