import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SActions = styled.div`
  --actions-top-padding: ${size(0)};
  --actions-right-padding: ${size(10)};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--actions-top-padding);
  padding-right: var(--actions-right-padding);

  @media ${media.lte('laptop')} {
    align-items: flex-start;

    --actions-top-padding: ${size(4)};
    --actions-right-padding: ${size(4)};
  }

  @media ${media.lte('tablet')} {
    --actions-top-padding: ${size(2)};
  }

  @media ${media.lte('mobile')} {
    --actions-top-padding: ${size(1)};
  }

  @media print {
    display: none;
  }
`

export const SButtonPlaceBid = styled(Button)`
  width: ${size(58)};

  @media ${media.lte('tablet')} {
    width: initial;
    padding: ${size(2)};
    flex: 2;
  }
`

export const SButtonEndNow = styled(Button)`
  @media ${media.lte('tablet')} {
    width: initial;
    padding: ${size(2)};
    flex: 1;
  }
`

export const SButtonPricing = styled(Button)`
  padding: ${size(4)};

  @media ${media.lte('tablet')} {
    padding: ${size(2)};
    flex: 1;
    white-space: nowrap;
  }
`
